export default function convertUserModule(items) {
    const itemMap = new Map();
    const rootItems = [];

    // Utility function to clean permission slugs
    const filterPermissions = (permissions) => {
        return permissions.map(permission => {
            const [, action] = permission.slug.split('.'); // Keep the action part after the dot
            return {
                ...permission,
                slug: action // Replace the slug with the action only
            };
        });
    };

    // Create a map of all items
    items.forEach(item => {
        const filteredPermissions = filterPermissions(item.permissions);
        if (item.parent_id) {
            itemMap.set(item.id, {
                title: item.name,
                path: '/' + (item.slug),
                permissions: filteredPermissions
            });
        } else {
            itemMap.set(item.id, {
                title: item.name,
                path: '/' + (item.slug),
                icon: item.icon,
                permissions: filteredPermissions
            });
        }
    });

    // Assign children to their respective parent items
    items.forEach(item => {
        if (item.parent_id) {
            const parent = itemMap.get(item.parent_id.id);
            if (parent) {
                if (!parent.children) {
                    parent.children = [];
                }
                parent.children.push(itemMap.get(item.id));
            }
        } else {
            rootItems.push(itemMap.get(item.id));
        }
    });

    return rootItems;
}
