import { useRef, useState } from "react";
// @mui
import {
  Stack,
  Alert,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// routes
// hooks
import useAuth from "hooks/useAuth";
// components
import Iconify from "components/Iconify";
import { PhoneValidation } from "utils/PhoneValidation";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import { AUTH_API, AUTH_SERVICE_API_KEY } from "config";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const { login } = useAuth();

  const passRef = useRef();
  const [step, setStep] = useState(0);
  const [errors, setErrors] = useState({
    phone_number: false,
    password: false,
  });
  const [infos, setInfos] = useState({
    phone_number: "",
    password: "",
    name: "",
    code: "",
    existCode: "",
    resetCode: "",
    newPassword: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const setInfoValueFunc = (name, e) => {
    const value = e.target.value;
    switch (name) {
      case "phone_number":
        setInfos((prevState) => ({ ...prevState, phone_number: value }));
        setErrors((prevState) => ({ ...prevState, phone_number: false }));
        break;
      case "password":
        setInfos((prevState) => ({ ...prevState, password: value }));
        setErrors((prevState) => ({ ...prevState, password: false }));
        break;
      case "name":
        setInfos((prevState) => ({ ...prevState, name: value }));
        break;
      case "code":
        setInfos((prevState) => ({ ...prevState, code: value }));
        break;
      case "existCode":
        setInfos((prevState) => ({ ...prevState, existCode: value }));
        break;
      case "resetCode":
        setInfos((prevState) => ({ ...prevState, resetCode: value }));
        break;
      case "newPassword":
        setInfos((prevState) => ({ ...prevState, newPassword: value }));
        break;
      default:
        break;
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    const infosFormData = new FormData();

    if (step === 0) {
      if (!PhoneValidation(infos.phone_number))
        setErrors((prevState) => ({ ...prevState, phone_number: true }));
      else {
        infosFormData.append("phone_number", infos.phone_number);
        // sending phone_number for checking the user status
        await axios
          .post(AUTH_API + "v1/account/check", infosFormData, {
            headers: {
              "Api-Key": AUTH_SERVICE_API_KEY,
            },
          })
          .then(({ data }) => {
            const check = data.data;
            if (check?.is_created === true) {
              // receiving the password from the user
              passRef.current.focus();
              setStep(1);
            } else {
              enqueueSnackbar("حساب کاربری با این شماره وجود ندارد!");
            }
          })
          .catch((e) => {
            // enqueueSnackbar(e.response.data.message, {variant: "error"})
          });
      }
      setIsSubmitting(false);
    } else if (step === 1) {
      if (!infos.password)
        setErrors((prevState) => ({ ...prevState, password: true }));
      else {
        infosFormData.append("phone_number", infos.phone_number);
        infosFormData.append("password", infos.password);

        const loginApi = async () => {
          await login(infosFormData);
          setIsSubmitting(false);
        };
        loginApi();
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && (
          <Alert severity="error">{errors.afterSubmit.message}</Alert>
        )}

        <div className={"w-full h-[56px] relative"}>
          <TextField
            fullWidth
            className={`${
              step === 0 ? "opacity-100" : "opacity-0 !absolute top-0 -scale-50"
            } transition-all duration-500`}
            name="email"
            autoFocus={step === 0}
            label="شماره تلفن"
            value={infos.phone_number}
            onChange={(e) => setInfoValueFunc("phone_number", e)}
            error={errors.phone_number}
            helperText={errors.phone_number && "شماره ی وارد شده اشتباه است"}
          />

          <TextField
            fullWidth
            inputRef={passRef}
            className={`${
              step === 1
                ? "opacity-100 scale-100"
                : "scale-150 opacity-0 absolute top-0"
            } transition-all duration-500`}
            name="password"
            label="رمز عبور"
            type={showPassword ? "text" : "password"}
            value={infos.password}
            onChange={(e) => setInfoValueFunc("password", e)}
            error={errors.password}
            helperText={errors.password && "رمز عبور وارد شده اشتباه است"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        {/*<RHFCheckbox name="remember" label="Remember me" />*/}
        {/*<Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>*/}
        {/*  Forgot password?*/}
        {/*</Link>*/}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        ورود
      </LoadingButton>
    </form>
  );
}
