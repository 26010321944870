// routes
import {PATH_DASHBOARD} from "./routes/paths";

// API
// ----------------------------------------------------------------------

// export const HOST_API = process.env.REACT_APP_HOST_API_KEY || 'http://127.0.0.1:8000/api/';
export const HOST_API = process.env.REACT_APP_HOST_API;// || "https://back-api.tatpnu.com/api/";
export const AUTH_API = process.env.REACT_APP_AUTH_API;// || "https://auth.tatpnu.com/api/";
export const AUTH_API_STORAGE = process.env.REACT_APP_AUTH_API_STORAGE;// || "https://auth.tatpnu.com/storage/";
export const AUTH_API_LMS = process.env.REACT_APP_AUTH_API_LMS;// || "https://lms-api.tatpnu.com/api/";
export const PROFILE_API = process.env.REACT_APP_PROFILE_API;// || "https://u-profile.tatpnu.com/api/";
export const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;// || "https://back-api.tatpnu.com/storage/";
export const IMAGE_HAMRAHBATO_URL = process.env.REACT_APP_IMAGE_HAMRAHBATO_URL;// || "https://u-profile.hamrahbato.com/storage/";
export const LMS_URL = process.env.REACT_APP_LMS_URL; // || "https://lms-api.tatpnu.com/api/v1/";
export const NAME_SIT = process.env.REACT_APP_PANEL_NAME; // || "tatpnu";
export const AUTH_SERVICE_API_KEY = process.env.REACT_APP_AUTH_SERVICE_API_KEY;
export const REACT_APP_PANEL_NAME = process.env.REACT_APP_PANEL_NAME;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
    MOBILE_HEIGHT: 64,
    MAIN_DESKTOP_HEIGHT: 88,
    DASHBOARD_DESKTOP_HEIGHT: 92,
    DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
    BASE_WIDTH: 260,
    DASHBOARD_WIDTH: 280,
    DASHBOARD_COLLAPSE_WIDTH: 88,
    //
    DASHBOARD_ITEM_ROOT_HEIGHT: 48,
    DASHBOARD_ITEM_SUB_HEIGHT: 40,
    DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
    NAVBAR_ITEM: 22,
    NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you set settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
    themeMode: "dark",
    themeDirection: "rtl",
    themeColorPresets: "default",
    themeLayout: "horizontal",
    themeStretch: false,
};

//// ----------------------------------------------------------------------
export const ORDER_STATUS_OPTIONS = [
    "لغو شده",
    "پرداخت شده",
    "در انتظار پرداخت",
];
