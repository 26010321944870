import { Navigate } from "react-router-dom";
import useAuth from "hooks/useAuth";

// کامپوننت گارد برای بررسی مجوزها
const PermissionGuard = ({ children, requiredPermissions }) => {
  const { user } = useAuth();

  // این تابع برای تولید مسیرهایی که کاربر دسترسی دارد استفاده می‌شود
  const generatePermissionUrls = (modules) => {
    return modules
      .map((module) => {
        if (module.permissions && module.permissions.length > 0) {
          return module.permissions.map((permission) => {
            return `${module.slug}/${permission.slug.split(".").pop()}`;
          });
        } else {
          return null;
        }
      })
      .flat()
      .filter(Boolean);
  };
  const urls = generatePermissionUrls(user.PannelModules);
  console.log("Generated URLs:", urls);
  const hasPermission = requiredPermissions.every((permission) =>
    urls.includes(permission)
  );
  console.log("requiredPermissions :", requiredPermissions);
  console.log(hasPermission);

  if (!hasPermission) {
    return <Navigate to="/404" replace />;
  }

  return children;
};

export default PermissionGuard;
