import {useRoutes} from 'react-router-dom';
import routesConfig from "routes/routeConfig";
import {useContext} from "react";
import {AuthContext} from "contexts/JWTContext";

/*const FilteredRoutes = () => {
    // const {user} = useContext(AuthContext); // Assuming your user's permissions are stored here
    // const [filteredRoutes, setFilteredRoutes] = useState([]);
    //
    // useEffect(() => {
    //     if (user.modules) setFilteredRoutes(filterRoutes(routesConfig, user.modules));
    // }, [user.modules]); // Recalculate when permissions change
    //
    // return useRoutes(filteredRoutes);
};*/

/*function filterRoutes(routes, permissions) {
    return routes.reduce((acc, route) => {
        const permission = permissions.find(p => route.path.startsWith(p.path));

        // Check if the user has permission to access this route
        if (!permission) return acc; // Skip routes without permission

        // Handle children routes if they exist
        const children = route.children ? filterRoutes(route.children, permissions) : undefined;

        // Construct the route with potential children
        acc.push({
            ...route,
            element: route.element, // Or wrap with a permission-based component
            children: children
        });

        return acc;
    }, []);
}

 export default FilteredRoutes;
*/


export default function Router() {
    return useRoutes(
        routesConfig.map((route) => ({
            path: route.path,
            element: route.element,
            children: route.children?.map((child) => ({
                path: child.path,
                element: child.element,
                index: child.index,
                children: child.children?.map((subChild) => ({
                    path: subChild.path,
                    element: subChild.element,
                    index: subChild.index,
                })),
            })),
        }))
    );
}

/*export default function Router() {
    const { userRoutes } = useContext(AuthContext);

    // Step 1: Separate static routes
    const staticRoutes = [
        { path: "/", element: <Navigate to="/dashboard/app" replace /> },
        {
            path: "auth",
            children: [
                {
                    path: "login",
                    element: <GuestGuard><Login /></GuestGuard>,
                },
            ],
        },
        {
            path: "dashboard",
            element: <AuthGuard><DashboardLayout /></AuthGuard>,
            children: [
                { path: "app", element: <GeneralApp /> },
                // Add more static child routes as needed
            ],
        },
    ];

    // Step 2: Structure dynamic user routes
    const structuredUserRoutes = userRoutes.map(route => {
        const { path, children } = route;

        return {
            path: path,
            element: <YourComponent />, // Replace with the appropriate component
            children: children?.map(child => ({
                path: child.path,
                element: <YourChildComponent />, // Replace with appropriate component
            })),
        };
    });

    // Step 3: Combine static and user routes
    const combinedRoutes = [...staticRoutes, ...structuredUserRoutes];

    // Debugging logs
    console.log("Static Routes:", staticRoutes);
    console.log("User Routes:", structuredUserRoutes);
    console.log("Combined Routes:", combinedRoutes);

    return useRoutes(combinedRoutes);
}*/


